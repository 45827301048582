'use client';

import { Box, Drawer, IconButton, List, ListItem, Typography } from '@mui/material';

import CloseSVG from '@/assets/icons/close.svg';
import * as CartStore from '@/stores/CartStore';

interface ICartOperation {
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const CartOperation: React.FC<ICartOperation> = ({ onClose }) => {
  return (
    <Box
      sx={{
        padding: '0 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '18px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: '18px', fontWeight: '500' }}>
          {`Cart(${Number(CartStore.store.orderIds.length)})`}
        </Typography>
        {CartStore.store.orderIds.length > 0 && (
          <Typography
            sx={{ color: 'rgba(229, 255, 101, 1)', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
            onClick={() => {
              CartStore.clearAllEthsciption();
            }}
          >
            Clear All
          </Typography>
        )}
      </Box>
      <IconButton onClick={onClose}>
        <CloseSVG />
      </IconButton>
    </Box>
  );
};

export default CartOperation;
