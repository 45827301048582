'use client';

import services from '@/services';
import { GetOrderByCartData } from '@/services/marketpalce/types';
import { proxy, subscribe } from 'valtio';

export interface ICartStore {
  open: boolean;
  cartOrderLoading: boolean;
  openResult: boolean;
  isSuccess: boolean;
  orderIds: string[];
  cartOrder: GetOrderByCartData | null;
}

export const store = proxy<ICartStore>({
  open: false,
  cartOrderLoading: false,
  openResult: false,
  isSuccess: false,
  orderIds: JSON.parse(typeof window != 'undefined' ? localStorage?.getItem('sweepOrderIds') || '[]' : '[]'),
  cartOrder: null,
});

subscribe(store.orderIds, () => {
  localStorage.setItem('sweepOrderIds', JSON.stringify(store.orderIds));
});

export const setIsSuccess = async (isSuccess: boolean) => {
  store.isSuccess = isSuccess;
};

export const setOpenResult = async (openResult: boolean) => {
  store.openResult = openResult;
};

export const setOpen = async (open: boolean) => {
  store.open = open;
  if (open) {
    store.cartOrderLoading = true;
    let _cartOrder = await services.marketplace.getOrderIDByCart(store.orderIds);
    if (_cartOrder?.orders) {
      const _orderIds = _cartOrder.orders.filter((item) => item.order.signature == '');
      store.cartOrder = _cartOrder;
      _orderIds.forEach((item) => {
        removeEthscription(item.order.orderHash);
      });
    }
    store.cartOrderLoading = false;
  } else {
    store.cartOrder = null;
  }
};

export const addEthscription = (orderId: string) => {
  store.orderIds.push(orderId);
};

export const removeEthscription = (orderId: string) => {
  const _orderIdIndex = store.orderIds?.findIndex((item) => item === orderId);
  const _orderCartIndex = store.cartOrder?.orders.findIndex((item) => item.order.orderHash === orderId);

  if (_orderIdIndex !== -1) {
    store.orderIds.splice(_orderIdIndex, 1);
  }

  if (_orderCartIndex != undefined && _orderCartIndex !== -1) {
    store.cartOrder?.orders.splice(_orderCartIndex, 1);
  }
};

export const findEthsciption = (orderId: string) => {
  return store.orderIds?.find((item) => item === orderId);
};

export const toggleEthscriptionToCart = (orderId: string) => {
  const _currentOrderId = findEthsciption(orderId);
  if (_currentOrderId) {
    removeEthscription(orderId);
  } else {
    addEthscription(orderId);
  }
};

export const clearAllEthsciption = () => {
  // clear array ，only splice
  store.orderIds.splice(0, store.orderIds.length);
  store.cartOrder = null;
};
