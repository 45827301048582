import { createTheme } from '@mui/material';

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#171A1F;',
    },
    primary: { main: '#E5FF65' },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
  },
});

export default customTheme;
