import BigNumber from 'bignumber.js';
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import { useSnapshot } from 'valtio';

import * as CartStore from '@/stores/CartStore';
import EthIcon from '@/assets/icons/eth16.svg';
import RemoveItem from '@/assets/icons/remove_cart_item.svg';

import { Fragment } from 'react';
import NftImage from '@/components/NftImage';

interface ICartListTable {}

const CartList = ({}: ICartListTable) => {
  const cartStore = useSnapshot(CartStore.store);

  function getListComponent() {
    if (cartStore.cartOrderLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            gridColumn: '1 / 4',
          }}
        >
          <CircularProgress sx={{ color: 'rgba(255,255,255,0.2)' }} />
        </Box>
      );
    }

    if (cartStore.cartOrder && cartStore.cartOrder?.orders?.length <= 0) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            gridColumn: '1 / 4',
          }}
        >
          No Data
        </Box>
      );
    }

    return cartStore.cartOrder?.orders.map((item, index) => {
      const { category, content } = item.item;
      const ethscriptionImg = {
        token: <NftImage content={item.item.icon} />,
        domain: (
          <Box
            sx={{
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Songti SC',
              fontSize: '12px',
              fontWeight: 900,
              color: '#000',
            }}
          >{`.${item.item.collectionName}`}</Box>
        ),
        nft: <NftImage content={item.item.content} />,
        image: null,
        text: null,
      };

      return (
        <Fragment key={item.order.orderHash + index}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'flex-start',
              gap: '10px',
              alignItems: 'center',
              opacity: item.order.signature.trim() == '' ? 0.3 : 1,
            }}
          >
            <Box
              sx={{
                width: '32px',
                height: '32px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#C5FA40',
                  borderRadius: '2px',
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                }}
              >
                {ethscriptionImg?.[category] || <Fragment />}
              </Box>
              <Box
                sx={{ position: 'absolute', top: '-8px', right: '-8px', cursor: 'pointer' }}
                onClick={() => {
                  CartStore.removeEthscription(item.order.orderHash);
                }}
              >
                <RemoveItem />
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: '14px', fontWeight: '500', marginBottom: '6px' }}
              >
                {category == 'domain' ? item.item.content.replace('data:,', '') : item.item.collectionName}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'flex-start', gap: '10px' }}>
                {category != 'domain' && (
                  <Typography
                    sx={{
                      minWidth: '44px',
                      color: 'rgb(255, 255, 255)',
                      fontSize: '12px',
                      fontWeight: '500',
                      padding: '2px 4px',
                      borderRadius: '3px',
                      border: '1px solid rgb(255, 255, 255)',
                      background: 'rgba(255, 255, 255, 0.05)',
                      boxSizing: 'border-box',
                    }}
                  >{`#${item.item.tokenId}`}</Typography>
                )}
                <Typography
                  sx={{
                    minWidth: '44px',
                    color: 'rgba(255, 255, 255, 0.45)',
                    fontSize: '12px',
                    fontWeight: '500',
                    padding: '2px 4px',
                    borderRadius: '3px',
                    border: '1px solid rgba(255, 255, 255, 0.20)',
                    background: 'rgba(255, 255, 255, 0.05)',
                    boxSizing: 'border-box',
                  }}
                >{`#${item.item.ethscriptionNumber}`}</Typography>
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              justifySelf: 'center',
              fontSize: '14px',
              color: item.order.signature.trim() == '' ? 'rgba(255,255,255,0.3)' : '#fff',
            }}
          >
            {item.order.quantity}
          </Typography>

          {item.order.signature.trim() == '' ? (
            <Typography sx={{ justifySelf: 'flex-end', color: '#D8346F' }}>Unavailable</Typography>
          ) : (
            <Typography sx={{ justifySelf: 'flex-end', fontSize: '14px', color: '#fff' }}>
              {`${
                item.order.price ? new BigNumber(item.order.price || 0).div(new BigNumber(10).pow(18)).toString() : '--'
              } ETH`}
              <EthIcon />
            </Typography>
          )}
        </Fragment>
      );
    });
  }

  return (
    <Box
      sx={{
        padding: '0 20px 28px',
        width: '100%',
        minHeight: '0',
        maxHeight: 'calc(100% - 190px)',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: '24px repeat(auto-fill, 42px)',
          gap: '28px 0',
          fontSize: '14px',
          fontWeight: '400',
          color: 'rgba(255,255,255,0.45)',
        }}
      >
        <Typography sx={{ height: '24px' }}>Items</Typography>
        <Typography sx={{ height: '24px', justifySelf: 'center' }}>Quantity</Typography>
        <Typography sx={{ height: '24px', justifySelf: 'flex-end' }}>Value</Typography>

        {getListComponent()}
      </Box>
    </Box>
  );
};

export default CartList;
