'use client';

import { Box, Drawer, useMediaQuery } from '@mui/material';
import { Fragment } from 'react';
import { useSnapshot } from 'valtio';

import CartSVG from '@/assets/icons/cart_normal.svg';
import CartOperation from './CartOperation';
import CartSettlement from './CartSettlement';
import CartList from './CartList';
import CartEmpty from './CartEmpty';
import * as CartStore from '@/stores/CartStore';
import ResultView from '../ResultView';

const CartDrawer = () => {
  const cartStore = useSnapshot(CartStore.store);
  const matches = useMediaQuery('(min-width:750px)');

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    CartStore.setOpen(open);
  };

  return (
    <Fragment>
      <Box
        onClick={toggleDrawer(!cartStore.open)}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <CartSVG color="#E5FF65" />
        {cartStore.orderIds.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              width: '14px',
              height: '14px',
              background: '#E5FF65',
              borderRadius: '50%',
              color: '#171A1F',
              fontSize: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: '-3px',
              top: '-2px',
            }}
          >
            {cartStore.orderIds.length}
          </Box>
        )}
      </Box>

      <Drawer
        anchor={'right'}
        open={cartStore.open}
        onClose={toggleDrawer(false)}
        sx={{
          '.MuiDrawer-paper': {
            padding: '20px 0 0 0',
            width: matches ? '460px' : '100%',
            height: 'calc(100% - 64px)',
            background: '#171A1F',
            boxShadow: '-4px 0px 12px 0px rgba(0, 0, 0, 0.25)',
            bottom: '0',
            top: 'unset',
            borderTop: '1px solid rgba(255, 255, 255, 0.05)',
            borderLeft: '1px solid rgba(255, 255,255, 0.05)',
          },
        }}
      >
        <CartOperation onClose={toggleDrawer(false)} />
        {cartStore.orderIds.length ? (
          <Fragment>
            <CartList />
            <CartSettlement />
          </Fragment>
        ) : (
          <CartEmpty onClose={toggleDrawer(false)} />
        )}
      </Drawer>
      <ResultView
        title="Checkout"
        open={cartStore.openResult}
        onClose={async () => {
          if (cartStore.isSuccess) {
          }
          CartStore.setOpenResult(false);
        }}
        isSuccess={cartStore.isSuccess}
      />
    </Fragment>
  );
};

export default CartDrawer;
