'use client';

import { Avatar, Box, Button, Drawer, IconButton, List, ListItem, Typography } from '@mui/material';
import { useRouter, useSearchParams } from 'next/navigation';
import { useSnapshot } from 'valtio';

import NoResult from '@/assets/images/no_result.svg';
import * as CartStore from '@/stores/CartStore';

interface ICartEmpty {
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const CartEmpty: React.FC<ICartEmpty> = ({ onClose }) => {
  const router = useRouter();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '120px' }}>
      <NoResult />
      <Typography>No items added to cart</Typography>
      <Button
        sx={{
          margin: '0 auto',
          width: '185px',
          height: '36px',
          mt: '13px',
          borderRadius: '46px',
          border: '1px solid #D5E970',
          textTransform: 'none',
          '&:hover': {
            color: '#000',
            bgcolor: 'rgba(229, 255, 101, 1)',
          },
        }}
        onClick={() => {
          router.push(`/market`);
          CartStore.setOpen(false);
        }}
      >
        Explore Collections
      </Button>
    </Box>
  );
};

export default CartEmpty;
