'use client';

import { Box, Drawer, IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAccount, useConnect } from 'wagmi';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Sentry from '@sentry/nextjs';

import ConnectWallet from '@/containers/ConnectWallet';
import LogoSVG from '@/assets/icons/logo.svg';
import LogoMobileSVG from '@/assets/icons/logo_mobile.svg';
import NavigationAPP from './NavigationAPP';
import * as GlobalStore from '@/stores/GlobalStore';
import H5MenuSvg from '@/assets/icons/h5_menu.svg';
import CartDrawer from '@/containers/CartDrawer';
import SearchInput from '@/components/SearchInput';
import { usePathname, useRouter } from 'next/navigation';

const Header = () => {
  const matches = useMediaQuery('(min-width:750px)');
  const pathname = usePathname();
  const isHome = useMemo(() => pathname === '/', [pathname]);
  const hidePcSearch = useMediaQuery('(max-width:1250px)');
  const { address, connector } = useAccount();
  const [openH5Menu, setOpenH5Menu] = useState<boolean>(false);
  const router = useRouter();
  const showPcSearch = useMemo(() => !isHome && !hidePcSearch, [pathname, hidePcSearch]);

  const goSearch = (e: string) => {
    router.replace(`/search?searchBy=${e}`);
  };

  const setSentryTag = () => {
    Sentry.setTag('account', address);
    Sentry.setTag('ConnectorName', connector?.name);
  };

  useEffect(() => {
    if (address) {
      GlobalStore.initRainbowKitAuthStatus(address);
    }
    if (address && connector) {
      setSentryTag();
    }
  }, [address, connector]);
  return (
    <Box
      sx={{
        width: '100vw',
        height: '64px',
        background: '#171A1F',
        backdropFilter: 'blur(4.5px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        left: '0',
        top: '0',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          onClick={() => {
            router.push('/');
          }}
          sx={{
            position: 'absolute',
            left: '24px',
            zIndex: 5,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          {matches || (!matches && isHome) ? <LogoSVG /> : <LogoMobileSVG />}
        </Box>
        {!matches && !isHome && (
          <SearchInput
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '52vw',
              height: 40,
              ml: '70px',
              borderRadius: '20px',
              border: '1px solid #2F343E',
              // backgroundColor: '#171A1F',
            }}
            placeholder={matches ? 'Search ethscriptions' : 'Ethscription'}
            onClear={() => {}}
            onClick={(val) => {
              goSearch(val as string);
            }}
            onEnter={(val) => {
              goSearch(val as string);
            }}
          />
        )}
        {matches && (
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              top: 0,
              left: '164px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'fill',
              zIndex: 2,
            }}
          >
            <NavigationAPP />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          right: matches ? '20px' : '54px',
          zIndex: 5,
        }}
      >
        {showPcSearch && (
          <SearchInput
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: matches ? '300' : '52vw',
              height: 40,
              mr: '20px',
              borderRadius: matches ? '6px' : '20px',
              border: '1px solid #2F343E',
              // backgroundColor: '#171A1F',
            }}
            placeholder={matches ? 'Search ethscriptions' : 'Ethscription'}
            onClear={() => {}}
            onClick={(val) => {
              goSearch(val as string);
            }}
            onEnter={(val) => {
              goSearch(val as string);
            }}
          />
        )}

        <Box
          sx={{
            zIndex: 5,
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {matches && <ConnectWallet />}
          <CartDrawer />
        </Box>
      </Box>
      {!matches && (
        <Box sx={{ position: 'absolute', right: '10px', zIndex: '5' }}>
          <IconButton
            onClick={() => {
              setOpenH5Menu(!openH5Menu);
            }}
          >
            <H5MenuSvg />
          </IconButton>
        </Box>
      )}
      {openH5Menu && !matches && (
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              top: '64px',
              background: '#171A1F',
              width: '220px',
              p: '24px 20px',
            },
          }}
          anchor={'right'}
          open={openH5Menu}
          onClose={() => setOpenH5Menu(false)}
        >
          <NavigationAPP
            onClick={() => {
              setOpenH5Menu(false);
            }}
          />
        </Drawer>
      )}
    </Box>
  );
};

export default Header;
