'use client';

import { Fragment, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useSnapshot } from 'valtio';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';

import * as CartStore from '@/stores/CartStore';
import * as EthscriptionsStore from '@/stores/EthscriptionsStore';
import { evmService } from '@/services';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import WalletConnectButton from '@/components/WalletConnectButton';
import ResultViewSweep from '../ResultViewSweep';
import { ContractReceipt } from 'ethers';
import { ICartStore } from '@/stores/CartStore';

const CartSettlement = () => {
  const cartStore = useSnapshot(CartStore.store) as ICartStore;
  const singer = useEthersSigner();
  const [isSumbit, setIsSubmit] = useState<boolean>(false);

  const [openResult, setOpenResult] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [txResult, setTxResult] = useState<ContractReceipt>();

  const accumlatePrice = cartStore.cartOrder?.orders.reduce((accumulator: string, currentValue) => {
    const _price = currentValue.order.signature.trim() == '' ? '0' : currentValue.order.price;
    return new BigNumber(accumulator).plus(_price).toString();
  }, '0');

  const efficientList = cartStore?.cartOrder?.orders.filter((item) => item.order.signature.trim() !== '') || [];

  async function handleOnClickBuySweep() {
    try {
      setIsSubmit(true);
      if (cartStore.cartOrder?.orders && singer) {
        const result = await evmService.etchMarketSweep.batchBuyEthscription({
          singer,
          ethscriptionSweep: { sweepAddress: cartStore.cartOrder.sweepAddress, orders: efficientList },
          sellPrice: accumlatePrice!,
        });
        if (result) {
          setIsSuccess(true);
          setTxResult(result);
        }
      }
    } catch (error) {
      setIsSuccess(false);
    } finally {
      setIsSubmit(false);
      setOpenResult(true);
    }
  }

  return (
    <Fragment>
      <Box sx={{ padding: '20px 20px 40px 20px', background: '#202229' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16PX' }}>
          <Typography sx={{ color: 'rgba(229, 255, 101, 1)', fontSize: '14px', fontWeight: '500' }}>
            Total Pay
          </Typography>
          <Box>
            <Typography sx={{ color: 'rgba(229, 255, 101, 1)', fontSize: '16px', fontWeight: '500' }}>
              {`${
                accumlatePrice ? new BigNumber(accumlatePrice || 0).div(new BigNumber(10).pow(18)).toString() : '--'
              } ETH`}
            </Typography>
            {/* <Typography
            sx={{
              color: 'rgba(255, 255, 255, 1)',
              fontSize: '12px',
              fontWeight: '500',
              padding: '2px 4px',
              textAlign: 'right',
            }}
          >
            ≈$1.33
          </Typography> */}
          </Box>
        </Box>

        <WalletConnectButton>
          <LoadingButton
            variant="contained"
            fullWidth
            disableElevation
            loading={isSumbit}
            disabled={cartStore.orderIds.length <= 0 || efficientList.length <= 0}
            color="primary"
            sx={{
              width: '100%',
              borderRadius: '46px',
              background: '#E5FF65',
              color: '#171A1F',
              fontSize: '14px',
              textTransform: 'capitalize',
              '&:hover': {
                background: 'rgba(229,255,101,0.7)',
              },
            }}
            onClick={handleOnClickBuySweep}
          >
            Buy Now
          </LoadingButton>
        </WalletConnectButton>
      </Box>
      <ResultViewSweep
        title="Confirmation"
        open={openResult}
        txResult={txResult!}
        onClose={async () => {
          setOpenResult(false);
          if (isSuccess) {
            CartStore.setOpen(false);
            CartStore.clearAllEthsciption();
            EthscriptionsStore.removeListedItem(efficientList);
          }
        }}
        isSuccess={isSuccess}
      />
    </Fragment>
  );
};

export default CartSettlement;
