'use client';

import services from '@/services';
import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';
import * as GlobalStore from '@/stores/GlobalStore';

export const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    const wagmiStore = JSON.parse(localStorage.getItem('wagmi.store') ?? '');
    const response = await services.did.getAuthNonce(wagmiStore.state.data.account);

    if (response?.code === 200) {
      return JSON.stringify(response.data);
    }

    return '';
  },
  createMessage: ({ nonce, address, chainId }) => {
    const authNonce = JSON.parse(nonce);
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: 'Sign in with Ethereum to the app.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce: authNonce.nonce,
      issuedAt: authNonce.issuedAt,
    });
  },
  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },
  verify: async ({ message, signature }) => {
    const response = await services.did.authVerify({ message: message.prepareMessage(), signature });

    if (response?.code === 200) {
      GlobalStore.setRainbowKitAuthStatus('authenticated', message.address, response.data.accessToken);
      return true;
    }
    GlobalStore.setRainbowKitAuthStatus('unauthenticated', message.address, '');

    return false;
  },
  signOut: async () => {
    GlobalStore.setRainbowKitAuthStatus('unauthenticated', '', '');
    // await fetch('/api/logout');
  },
});
