'use client';
import merge from 'lodash.merge';
import { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import customTheme from '@/constants/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSnapshot } from 'valtio';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import {
  RainbowKitProvider,
  RainbowKitAuthenticationProvider,
  getDefaultWallets,
  connectorsForWallets,
  Theme,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  okxWallet,
  walletConnectWallet,
  tokenPocketWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { gateWallet } from '@/wallets/gateWallet';
import { QueryClient, QueryClientProvider } from 'react-query';

import { authenticationAdapter } from './authenticationAdapter';
import * as GlobalStore from '@/stores/GlobalStore';
import { SERVER_CONFIG } from '@/constants/config';

dayjs.extend(duration);

const { chains, publicClient, webSocketPublicClient } = configureChains(SERVER_CONFIG.CHAIN, [publicProvider()]);
const projectId = 'c5e287b868fa350466d5a0d947a65042';

const { wallets } = getDefaultWallets({
  appName: 'EtchMarket APP',
  projectId,
  chains,
});

const AppInfo = {
  appName: 'EtchMarket APP',
};

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      okxWallet({ projectId, chains }),
      tokenPocketWallet({ projectId, chains }),
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      gateWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const myCustomTheme: Theme = merge(darkTheme(), {
  colors: {
    accentColor: '#D5E970',
    accentColorForeground: '#171A1F',
  },
} as Theme);

const queryClient = new QueryClient();

export function Providers({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);
  const globalStore = useSnapshot(GlobalStore.store);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Toaster
          position="top-right"
          containerStyle={{
            top: 80,
            right: 20,
          }}
          toastOptions={{
            success: {
              duration: 3000,
            },
            error: {
              duration: 3000,
            },
            loading: {
              duration: 3000,
            },
          }}
        />
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={globalStore.rainbowKitAuthStatus}>
            <RainbowKitProvider chains={chains} modalSize="wide" appInfo={AppInfo} theme={myCustomTheme}>
              {mounted && children}
            </RainbowKitProvider>
          </RainbowKitAuthenticationProvider>
        </WagmiConfig>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
